import aboutImg from "../img/placeholder/coming-soon-about.png";
import instagramLogo from "../img/icons/Instagram_logo_2016.svg";
import discordLogo from "../img/icons/636e0a69f118df70ad7828d4_icon_clyde_blurple_RGB.svg";
import steamLogo from "../img/icons/Steam_icon_logo.svg";
import spotifyLogo from "../img/icons/Spotify_icon.svg";
import gitHubLogo from "../img/icons/github-mark-white.svg";

import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  return (
    <div
      id="about"
      className=" w-5/6 lg:w-4/6 mx-auto mb-22 mb-24 lg:mb-32"
    >
      <h1 className="text-5xl lg:text-8xl font-black text-stroked lg:text-stroked stroke-[--text-color]">
        {t("about.title")}
      </h1>
      
      <div className="bg-gradient-to-r from-[--gradient-first] to-[--gradient-second]  rounded-lg w-64 h-8 -ml-4 -mt-5 mb-2 lg:w-[30rem] lg:h-16 lg:-mt-10 lg:-ml-4 lg:mb-6"></div>

      
      <div className="flex flex-col">
        <p className=" text-3xl lg:text-3xl font-bold">{t("about.name")}</p>
        <div className="lg:flex ">
          <div className="lg:w-1/3 flex flex-col lg:mr-8 justify-between">
            <div className="lg:border-y-2 border-y border-secondary-color lg:text-2xl ">
              <p className="leading-relaxed">{t("about.age")}</p>
              <p className="leading-relaxed">{t("about.country")}</p>
       
            </div>

            <div>
              <p className="text-2xl lg:text-3xl font-bold">
                {t("about.about1.title")}
              </p>
              <div className="lg:border-y-2 border-y border-secondary-color lg:text-2xl mb-4 ">
                <p className="leading-relaxed">{t("about.about1.text1")}</p>
                
                <p className="leading-relaxed">{t("about.about1.text2")}</p>
                
                <p  className="leading-relaxed"> {t("about.about1.text3")}</p>
                
              </div>
            </div>

            <div>
              <p className="text-2xl lg:text-3xl font-bold">
                {t("about.about2.title")}
              </p>
              <div className="lg:border-y-2 border-y border-secondary-color lg:text-2xl">
                <a
                  href=""
                  target="_blank"
                  className="leading-relaxed"
                  rel="noreferrer"
                >
                  {t("about.about2.text1")}
                </a>
                <br />
                <a
                  href=""
                  target="_blank"
                  className="leading-relaxed"
                  rel="noreferrer"
                >
                  {t("about.about2.text2")}
                </a>
                <br />
                <a
                  href=""
                  target="_blank"
                  className="leading-relaxed"
                  rel="noreferrer"
                >
                  {t("about.about2.text3")}
                </a>
                <br />
              </div>
            </div>

            <div>
              <p className="text-2xl lg:text-3xl font-bold">
                {t("about.about3.title")}
              </p>
              <div className="border-t lg:border-y-2 border-secondary-color lg:text-2xl">
                <a
                  href=""
                  target="_blank"
                  className="leading-relaxed"
                  rel="noreferrer"
                >
                  {t("about.about3.text1")}
                </a>
                <br />
                <a
                  href=""
                  target="_blank"
                  className="leading-relaxed"
                  rel="noreferrer"
                >
                  {t("about.about3.text2")}
                </a>
                <br />
                <a
                  href=""
                  target="_blank"
                  className="leading-relaxed"
                  rel="noreferrer"
                >
                  {t("about.about3.text3")}
                </a>
                <br />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between lg:w-2/3  lg:border-y-2 border-y border-secondary-color">
            <img
              className="h-full my-8 object-cover"
              src={aboutImg}
              alt="About"
            ></img>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="hidden lg:block border-b-2 border-secondary-color h-12 mr-8 w-1/3">
            <div className="flex flex-row ">
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://github.com/fabianrapold"
                rel="noreferrer"
              >
                <img alt="GitHub" className="h-8" src={gitHubLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://www.instagram.com/fabianrapold/"
                rel="noreferrer"
              >
                <img alt="Instagram" className="h-8" src={instagramLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://discord.com/users/918946949883908117"
                rel="noreferrer"
              >
                <img alt="Discord" className="h-8" src={discordLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://steamcommunity.com/profiles/76561198834352950/"
                rel="noreferrer"
              >
                <img alt="Steam" className="h-8" src={steamLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://open.spotify.com/user/e4c3ijgz626j2sjzk5b9ri1uw?si=ba766234a07e4448"
                rel="noreferrer"
              >
                <img alt="Spotify" className="h-8" src={spotifyLogo} />
              </a>
            </div>
          </div>

          <div className="hidden lg:block border-b-2 border-secondary-color h-12 w-2/3"></div>
          <div className="lg:hidden border-b border-secondary-color h-12 w-full">
            <div className="flex flex-row ">
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://www.instagram.com/fabianrapold/"
                rel="noreferrer"
              >
                <img alt="Instagram" className="h-8" src={instagramLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://github.com/fabianrapold"
                rel="noreferrer"
              >
                <img alt="GitHub" className="h-8" src={gitHubLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://discord.com/users/918946949883908117"
                rel="noreferrer"
              >
                <img alt="Discord" className="h-8" src={discordLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://steamcommunity.com/profiles/76561198834352950/"
                rel="noreferrer"
              >
                <img alt="Steam" className="h-8" src={steamLogo} />
              </a>
              <a
                className="my-2 mr-4"
                target="_blank"
                href="https://open.spotify.com/user/e4c3ijgz626j2sjzk5b9ri1uw?si=ba766234a07e4448"
                rel="noreferrer"
              >
                <img alt="Spotify" className="h-8" src={spotifyLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
