/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 .\star.glb --transform 
Files: .\star.glb [10.21MB] > C:\Users\fabia\Downloads\star-transformed.glb [768.31KB] (92%)
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Star(props) {
  const { nodes, materials } = useGLTF('/models/star/star-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Torus.geometry} material={materials.silver} position={[0, 0.265, -1.466]} rotation={[0, -0.01, -1.607]} />
    </group>
  )
}

useGLTF.preload('/models/star/star-transformed.glb')
