import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

import logoLight from "../img/logo-light.svg";
import logoDark from "../img/logo-dark.svg";

import { useTranslation } from "react-i18next";
import LanguageButton from "./LanguageSwitcher";
import ThemeSwitcher from "./ThemeSwitcher";

function NavBar() {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [logo, setLogo] = useState(logoLight);

  


  const genericHamburgerLine = `h-1 w-8 my-1 bg-text-color transition ease transform duration-300 rounded-sm`;

  useEffect(() => {
    


  
  }, []);

  return (
    <header className="font-['Roboto']">
      <div className="flex justify-between w-[95%] lg:w-4/6 mx-auto">
        <div className="mt-2 ml-2 lg:ml-0">
          <Link to="/">
            <img className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px]" alt="Logo" src={logo} />
          </Link>
        </div>

        <nav>
          <div className="hidden lg:block">
            <ul className="flex mt-6">
              <li className="mr-6">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "permanent-underline" : "underline-animation"
                  }
                >
                  {t("nav.home")}
                </NavLink>
              </li>
              <li className="mr-6">
                <a href="/#about" className="underline-animation">
                  {t("nav.about")}
                </a>
              </li>
              <li className="mr-6">
                <a href="/#projects" className="underline-animation">
                  {t("nav.projects")}
                </a>
              </li>
              <li className="mr-6">
                <a href="/#contact" className="underline-animation">
                  {t("nav.contact")}
                </a>
              </li>
              <li className="mr-6">
                <LanguageButton />
              </li>
              <li className="mr-6">
                <ThemeSwitcher logo={logo} setLogo={setLogo} logoDark={logoDark} logoLight={logoLight}/>
              </li>
            </ul>
          </div>

          <div className="lg:hidden">
            <button
              id="menu button"
              aria-label="menu button"
              className="flex flex-col h-12 w-12 justify-center items-center group"
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <div
                className={`${genericHamburgerLine} ${
                  isNavOpen ? "rotate-45 translate-y-3" : ""
                }`}
              />
              <div className={`${genericHamburgerLine} ${isNavOpen ? "opacity-0" : ""}`} />
              <div
                className={`${genericHamburgerLine} ${
                  isNavOpen ? "-rotate-45 -translate-y-3" : ""
                }`}
              />
            </button>
          </div>
        </nav>
      </div>

      <div className={isNavOpen ? "block text-center" : "hidden"}>
        <ul className="mr-3">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-primary-color font-bold leading-loose"
                  : "hover:text-primary-color leading-loose"
              }
            >
              {t("nav.home")}
            </NavLink>
          </li>
          <li>
            <a href="/#about" className="hover:text-primary-color leading-loose">
              {t("nav.about")}
            </a>
          </li>
          <li>
            <a href="/#projects" className="hover:text-primary-color leading-loose">
              {t("nav.projects")}
            </a>
          </li>
          <li>
            <a href="/#contact" className="hover:text-primary-color leading-loose">
              {t("nav.contact")}
            </a>
          </li>
          <li>
            <LanguageButton />
          </li>
          <li className="mt-2">
            <ThemeSwitcher logo={logo} setLogo={setLogo} logoDark={logoDark} logoLight={logoLight} />
          </li>
        </ul>
      </div>
    </header>
  );
}

export default NavBar;
