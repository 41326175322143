function LoadingSpinner() {
  return (
    <div
      className="animate-spin inline-block w-12 h-12 lg:w-16 lg:h-16 border-[3px] border-current border-t-transparent text-second-color rounded-full m-auto"
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default LoadingSpinner;
