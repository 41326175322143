import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const getLanguage = () => {
    let language
    if (localStorage.getItem("i18nextLng") === null) {
        language = navigator.language || navigator.userLanguage; 

      } else {
        language = localStorage.getItem("i18nextLng") || "en"
      }
    return language;
    
}

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
    resources: {
      en: {
        translation: {
            home_start : {
                welcome: "Welcome...",
                text: "to my website. Scroll down to see more or click on the button to contact me.",
                button: "Contact Me",
            }, 
            about : {
                title : "About Me",
                name : "Fabian Rapold",
                age : "21",
                country : "Switzerland",
                about_text : "",
                about1 : {
                    title: "Hobbies",
                    text1 : "-",
                    text2 : "-",
                    text3 : "-"

                },
                about2 : {
                    title: "Currently watching",
                    text1 : "-",
                    text2 : "-",
                    text3 : "-"

                },
                about3 : {
                    title: "Currently listening",
                    text1 : "-",
                    text2 : "-",
                    text3 : "-"

                },
            }, 
            projects : {
                title : "Projects",
                p: "P",
                r: "r",
                o: "o",
                j: "j",
                e: "e",
                c: "c",
                t: "t",
                s: "s",
                project1 : "coming soon",
                project2 : "coming soon",
                project3 : "coming soon",
                project4 : "coming soon",
                project5 : "coming soon",
                project6 : "coming soon",
                project7 : "coming soon",
                project8 : "coming soon",
                project9 : "coming soon"
            }, 
            contact : {
                title: "Contact",
                mail: "contact@fabianrapold.com"
            },
            nav : {
                home: "Home",
                about : "About Me",
                projects : "Projects",
                contact : "Contact"
            },
            footer : {
                copyright : "Fabian Rapold",
                home : "Home",
                contact : "Contact",
                legal : "Legal"
            },
            legal : {
                legal_notice : {
                    title : "Legal Notice",
                    name : "Fabian Rapold",
                    mail : "contact@fabianrapold.com",
                    country : "",
                    city : "",
                    street : ""

                },
                privacy : {
                    title : "Privacy",
                    text: "This website does not collect or store any personal data."
                },
            },
            no_page : {
                error : "Error 404 – Page not found",
                link : "Home"
            }

        }
      },
      de: {
        translation: {
            home_start : {
                welcome: "Willkommen...",
                text: "auf meiner Website. Scrolle nach unten, um mehr zu sehen, oder klicke auf den Button, um mich zu kontaktieren.",
                button: "Kontakt",
            }, 
            about : {
                title : "Über mich",
                name : "Fabian Rapold",
                age : "21",
                country : "Schweiz",
                about_text : "",
                about1 : {
                    title: "Hobbys",
                    text1 : "-",
                    text2 : "-",
                    text3 : "-"

                },
                about2 : {
                    title: "Schaue ich gerade",
                    text1 : "-",
                    text2 : "-",
                    text3 : "-"

                },
                about3 : {
                    title: "Höre ich gerade",
                    text1 : "-",
                    text2 : "-",
                    text3 : "-"

                },
            }, 
            projects : {
                title : "Projekte",
                p: "P",
                r: "r",
                o: "o",
                j: "j",
                e: "e",
                c: "k",
                t: "t",
                s: "e",
                project1 : "coming soon",
                project2 : "coming soon",
                project3 : "coming soon",
                project4 : "coming soon",
                project5 : "coming soon",
                project6 : "coming soon",
                project7 : "coming soon",
                project8 : "coming soon",
                project9 : "coming soon"
            }, 
            contact : {
                title: "Kontakt",
                mail: "contact@fabianrapold.com"
            },
            nav : {
                home: "Home",
                about : "Über mich",
                projects : "Projekte",
                contact : "Kontakt"
            },
            footer : {
                copyright : "Fabian Rapold",
                home : "Home",
                contact: "Kontakt",
                legal : "Datenschutz"
            },
            legal : {
                legal_notice : {
                    title : "Impressum",
                    name : "Fabian Rapold",
                    mail : "contact@fabianrapold.com",
                    country : "",
                    city : "",
                    street : ""

                },
                privacy : {
                    title : "Datenschutz",
                    text: "Diese Website sammelt und speichert keine persönlichen Daten."
                },
            },
            no_page : {
                error : "Error 404 – Seite nicht gefunden",
                link : "Home",
                
            }
        }
      },
    },
    lng: getLanguage(),
  });



  

export default i18n;