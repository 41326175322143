import HomeStart from "../views/HomeStart";
import About from "../views/About";
import Projects from "./Projects";
import Contact from "./Contact";


function Home() {
  return (
    <div id="home">
      <HomeStart />
      <About />
      <Projects />
      <Contact />
      
    </div>
  );
}

export default Home;
