import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import LoadingSpinner from "../components/LoadingSpinner.js";
import { useTranslation } from 'react-i18next';

import { Star } from "../components/models/Star.jsx"
import { Environment } from '@react-three/drei'
import StarHDR from '../img/hdri/abandoned_greenhouse_1k.hdr';


function HomeStart() {
  const { t } = useTranslation();

  

  const isLargeDevice = useMediaQuery("(min-width : 1024px)");
  const isMediumDevice = useMediaQuery("(min-width : 768px)");
  const isExtraLargeDevice = useMediaQuery("(min-width : 1280px)");


  return (
    <div id="home-start" className="flex flex-col w-5/6 lg:w-4/6 mx-auto h-screen font-['Roboto']">


    <div className="flex flex-col lg:flex-row h-5/6"> 
      <div className=" mt-8 lg:self-center lg:mb-56 animate-slide_in_left">
        <h2 className="font-bold text-[3rem] lg:text-[3.75rem] leading-relaxed" >{t("home_start.welcome")}</h2>
        <p className="text-xl font-medium">
        {t("home_start.text")}
        </p>
        <div className="mt-4">
        <a href="/#contact" className="bg-gradient-to-r from-[--gradient-first] to-[--gradient-second] border border-[--text-color] lg:text-xl p-2 font-medium rounded-md">{t("home_start.button")}</a>

        </div>
      </div>


      <div className="h-full w-full flex">
      <Suspense fallback={<LoadingSpinner />}>

        <Canvas camera={{ fov: 75, position: [5, 0, -3]}}>
        <Environment files={StarHDR}/>

        <Star scale={isExtraLargeDevice ? 0.85: isLargeDevice ? 0.85 : isMediumDevice ? 0.75 : 0.8} />

          
          <OrbitControls autoRotate={true} enableZoom={false} enableRotate={true} enablePan={false} />
          
        </Canvas>
        </Suspense>
      </div>
      </div>

      <div className="flex flex-row justify-between mx-auto w-full">
        <div className="border-l-[40px] lg:border-l-[90px] border-l-transparent border-t-[20px] lg:border-t-[60px] border-t-[--triangle-color] border-r-[40px] lg:border-r-[90px] border-r-transparent rounded"></div>

        <div className="border-l-[40px] lg:border-l-[90px] border-l-transparent border-t-[20px] lg:border-t-[60px] border-t-[--triangle-color] border-r-[40px] lg:border-r-[90px] border-r-transparent rounded"></div>

        <div className="border-l-[40px] lg:border-l-[90px] border-l-transparent border-t-[20px] lg:border-t-[60px] border-t-[--triangle-color] border-r-[40px] lg:border-r-[90px] border-r-transparent rounded"></div>
      </div>
    </div>
  );
}

export default HomeStart;
