import { Link } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import LoadingSpinner from "../components/LoadingSpinner.js";
import { useTranslation } from "react-i18next";
import { Environment } from '@react-three/drei'
import { Star } from "../components/models/Star.jsx"

import StarHDR from '../img/hdri/abandoned_greenhouse_1k.hdr';

function NoPage() {
  const { t } = useTranslation();

  const isLargeDevice = useMediaQuery("(min-width : 1024px)");
  const isMediumDevice = useMediaQuery("(min-width : 768px)");
  const isExtraLargeDevice = useMediaQuery("(min-width : 1280px)");

  return (
    <div
      id="no-page"
      className="flex flex-col text-white w-5/6 lg:w-4/6 mx-auto h-[calc(100vh-3rem-59.23px)] font-['Roboto']"
    >
      <div className="flex flex-col lg:flex-row h-full">
        <div className=" mt-8 lg:self-center lg:mb-56 animate-slide_in_left">
          <h2 className="font-bold text-[3rem] lg:text-[3.75rem] leading-relaxed">
            {t("no_page.error")}
          </h2>
          
          <div className="mt-4">
            <Link
              to="/"
              className="bg-gradient-to-r from-[--gradient-first] to-[--gradient-second] border border-[--text-color] lg:text-xl p-2 font-medium rounded-md"
            >
              {t("no_page.link")}
            </Link>
          </div>
        </div>

        <div className="h-full w-full flex pt-12">
          <Suspense fallback={<LoadingSpinner />}>
          <Canvas camera={{ fov: 75, position: [5, 0, -3]}}>
              <Environment files={StarHDR}/>

              <Star scale={isExtraLargeDevice ? 0.85: isLargeDevice ? 0.85 : isMediumDevice ? 0.75 : 0.8} />
              <OrbitControls autoRotate={true} enableZoom={false} enableRotate={true} enablePan={false} />

            </Canvas>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default NoPage;
