import { useTranslation } from "react-i18next";

import instagramLogo from "../img/icons/Instagram_logo_2016.svg";
import gitHubLogo from "../img/icons/github-mark-white.svg";


function Contact() {
  const { t } = useTranslation();

  return (
    <div
      id="contact"
      className="flex flex-col w-5/6 lg:w-4/6 mx-auto mb-32"
    >
      <div className="grid grid-cols-4 xl:grid-cols-10 grid-rows-6 gap-4 xl:gap-5 h-[80vh] lg:h-auto w-full">
        <div className="flex bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl col-span-4">
          <h1 className="m-auto text-5xl lg:text-8xl font-bold text-center">
            {t("contact.title")}
          </h1>
        </div>

        <div className="bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-4 xl:row-span-2 xl:col-span-4"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-2"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-1"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-1"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-2"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-3"></div>
        <div className="bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-2 flex justify-between">
          <div className="border-l-[40px] xl:border-l-[60px] border-l-transparent border-t-[20px] xl:border-t-[40px] border-t-[--triangle-color] border-r-[40px] xl:border-r-[60px] border-r-transparent rounded m-auto"></div>
        </div>
        <div className="bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-2 flex justify-between">
          <div className="border-l-[40px] xl:border-l-[60px] border-l-transparent border-t-[20px] xl:border-t-[40px] border-t-[--triangle-color] border-r-[40px] xl:border-r-[60px] border-r-transparent rounded m-auto"></div>
        </div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-1"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-2"></div>

        <div className="flex bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-4">
          <a href="mailto:contact@fabianrapold.com" className="m-auto text-center font-medium text-xl xl:text-3xl">
            {t("contact.mail")}
          </a>
        </div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-1"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-2"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-2"></div>

        <div className="hidden xl:block bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-2"></div>

        <div className="bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-2 col-span-2"></div>

        <div className="bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-2"></div>

        <div className=" bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-1">
        <div className="m-4 md:m-8 xl:m-2 2xl:m-6">
            <a
              target="_blank"
              href="https://github.com/fabianrapold"
              rel="noreferrer"
            >
              <img className="m-auto w-full h-full" alt="GitHub" src={gitHubLogo} />
            </a>
          </div>
        </div>
        <div className=" bg-transparent lg:border-2 border border-secondary-color hover:scale-105 transition ease-in-out rounded-xl row-span-1 col-span-1">
          <div className="m-4 md:m-5">
            <a
              target="_blank"
              href="https://www.instagram.com/fabianrapold/"
              rel="noreferrer"
            >
              <img alt="Instagram" className="m-auto w-full h-full" src={instagramLogo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
