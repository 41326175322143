import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className=" h-12 text-sm w-5/6 lg:w-full mx-auto">
      <div className="flex justify-between mx-auto lg:w-4/6">
      <div className="ml-1 my-auto hover:opacity-80 ">
        <Link to="/legal">
          © {new Date().getFullYear()} {t("footer.copyright")}
        </Link>
      </div>

      <ul className="flex my-auto">
        <li className="mr-2 lg:mr-6 hover:opacity-80">
          <Link to="/#home ">{t("footer.home")}</Link>
        </li>
        
        <li className="mr-2 lg:mr-6 hover:opacity-80 ">
          <Link to="/legal">{t("footer.legal")}</Link>
        </li>
      </ul>
      </div>
    </footer>
  );
}

export default Footer;
