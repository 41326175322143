import React, { useEffect, useState } from "react";

import projImg1 from "../img/placeholder/coming-soon.png";
import projImg2 from "../img/placeholder/coming-soon.png";
import projImg3 from "../img/placeholder/coming-soon.png";
import projImg4 from "../img/placeholder/coming-soon.png";
import projImg5 from "../img/placeholder/coming-soon.png";
import projImg6 from "../img/placeholder/coming-soon.png";
import projImg7 from "../img/placeholder/coming-soon.png";
import projImg8 from "../img/placeholder/coming-soon.png";
import projImg9 from "../img/placeholder/coming-soon.png";
import { useMediaQuery } from "@uidotdev/usehooks";

import Tilt from "react-parallax-tilt";
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation();

  const isXlDevice = useMediaQuery("(min-width : 1280px)");

  const [projName, setProjName] = useState([
    t("projects.project1"),
    t("projects.project2"),
    t("projects.project3"),
    t("projects.project4"),
    t("projects.project5"),
    t("projects.project6"),
    t("projects.project7"),
    t("projects.project8"),
    t("projects.project9"),
  ]);
  const [projTitle, setProjTitle] = useState(projName[0]);
  const [projImg, setProjImg] = useState(projImg1);
  const [projIndex, setProjIndex] = useState(0);

  function switchProject(index) {
    setProjIndex(index);
    setProjTitle(projName[index]);
  }

  useEffect(() => {
    if (projIndex === 0) {
      setProjImg(projImg1);
    } else if (projIndex === 1) {
      setProjImg(projImg2);
    } else if (projIndex === 2) {
      setProjImg(projImg3);
    } else if (projIndex === 3) {
      setProjImg(projImg4);
    } else if (projIndex === 4) {
      setProjImg(projImg5);
    } else if (projIndex === 5) {
      setProjImg(projImg6);
    } else if (projIndex === 6) {
      setProjImg(projImg7);
    } else if (projIndex === 7) {
      setProjImg(projImg8);
    } else if (projIndex === 8) {
      setProjImg(projImg9);
    }
  }, [projIndex]);

  return (
    <div id="projects" className="w-5/6 lg:w-4/6 mx-auto mb-24 lg:mb-40">
      <Tilt
        glareEnable={isXlDevice}
        tiltMaxAngleX={3}
        tiltMaxAngleY={3}
        perspective={1000}
        glareMaxOpacity={0.1}
        tiltEnable={isXlDevice}
      >
        <div className="flex flex-row justify-between ">
          <h1 className="absolute -ml-[8.25rem] md:-ml-[12rem] lg:-ml-[14rem] xl:-ml-[20rem] 2xl:-ml-[20rem] mt-[8rem] md:mt-[10rem] lg:mt-[12rem] xl:mt-[16rem] 2xl:mt-[16rem] font-black text-7xl md:text-[6rem] lg:text-[7rem] xl:text-[10rem] 2xl:text-[10rem] -rotate-90 cursor-default">
            {/*t("projects.title")*/}
            <span className="hover:text-project-title-color">{t("projects.p")}</span>
            <span className="hover:text-project-title-color">{t("projects.r")}</span>
            <span className="hover:text-project-title-color">{t("projects.o")}</span>
            <span className="hover:text-project-title-color">{t("projects.j")}</span>
            <span className="hover:text-project-title-color">{t("projects.e")}</span>
            <span className="hover:text-project-title-color">{t("projects.c")}</span>
            <span className="hover:text-project-title-color">{t("projects.t")}</span>
            <span className="hover:text-project-title-color">{t("projects.s")}</span>
          </h1>

          <div className="w-full">
            <div className="bg-gradient-to-r from-[--gradient-first] to-[--gradient-second]  border border-secondary-color rounded pr-4 py-4 pl-16 lg:pl-32">
              <div className="flex flex-col lg:flex-row justify-between text-3xl md:text-2xl xl:text-3xl font-bold">
                <div className="lg:border-y-2 border-y border-secondary-color ">
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(0)}
                      className=" cursor-pointer"
                    >
                      {projName[0]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 0 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(1)}
                      className=" cursor-pointer"
                    >
                      {projName[1]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 1 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(2)}
                      className=" cursor-pointer"
                    >
                      {projName[2]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 2 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                </div>

                <div className="lg:border-y-2 border-secondary-color">
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(3)}
                      className=" cursor-pointer"
                    >
                      {projName[3]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 3 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>

                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(4)}
                      className=" cursor-pointer  "
                    >
                      {projName[4]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 4 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(5)}
                      className=" cursor-pointer"
                    >
                      {projName[5]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 5 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                </div>

                <div className="lg:border-y-2 border-y border-secondary-color">
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(6)}
                      className=" cursor-pointer"
                    >
                      {projName[6]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 6 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(7)}
                      className=" cursor-pointer"
                    >
                      {projName[7]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 7 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                  <div className="transition ease-in-out hover:scale-110">
                    <a
                      href="/#projects"
                      onMouseOver={() => switchProject(8)}
                      className=" cursor-pointer"
                    >
                      {projName[8]}
                    </a>
                  </div>
                  <img
                    alt="Project"
                    className={
                      projIndex === 8 ? "lg:hidden w-full my-4" : "hidden"
                    }
                    src={projImg}
                  ></img>
                </div>
              </div>

              <img
                alt="Project"
                className="hidden lg:block w-full my-4"
                src={projImg}
              ></img>
              <div className="hidden lg:flex flex-row justify-between">
                <div className="lg:border-y-2 border-y border-secondary-color w-9/12"></div>
                <h2 className="text-6xl md:text-3xl xl:text-4xl 2xl:text-5xl font-bold w-full text-center">
                  {projTitle}
                </h2>
                <div className="lg:border-y-2 border-y border-secondary-color border-secondary-color w-9/12"></div>
              </div>
            </div>
            <div className="lg:hidden bg-gradient-to-r from-[--gradient-first] to-[--gradient-second] mt-2 h-10 border border-secondary-color rounded"></div>
          </div>

          <div className="hidden bg-[--gradient-second] lg:block w-16 ml-4 border border-secondary-color rounded"></div>
        </div>
      </Tilt>
    </div>
  );
}

export default Projects;
