import { useTranslation } from "react-i18next";

function Legal() {
  const { t } = useTranslation();

  return (
    <div
      id="legal"
      className=" w-5/6 lg:w-4/6 mx-auto min-h-[calc(100vh-3rem-59.23px)] pt-12 lg:pt-24"
    >
      <div className="mb-8">
        <h2 className="font-bold text-[3rem] lg:text-[3.75rem] leading-relaxed">
          {t("legal.privacy.title")}
        </h2>
        <p>{t("legal.privacy.text")}</p>
      </div>

      <div className="mb-8">
        <h2 className="font-bold text-[3rem] lg:text-[3.75rem] leading-relaxed">
          {t("legal.legal_notice.title")}
        </h2>
        <p className="leading-loose">{t("legal.legal_notice.name")}</p>
        <p className="leading-loose">{t("legal.legal_notice.mail")}</p>
        <p className="leading-loose">{t("legal.legal_notice.country")}</p>
        <p className="leading-loose">{t("legal.legal_notice.city")}</p>
        <p className="leading-loose">{t("legal.legal_notice.street")}</p>
      </div>
    </div>
  );
}

export default Legal;
