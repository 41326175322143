import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";


function Layout() {
  return (

    <div className="bg-gradient-to-r from-[--gradient-first] to-[--gradient-second] text-[--text-color] font-['Roboto'] ">
      <svg
        className="pointer-events-none fixed isolate z-50 mix-blend-soft-light"
        width="100%"
        height="100%"
      >
        <filter id="noise">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.7"
            stitchTiles="stitch"
          />
        </filter>
        <rect width="100%" height="100%" filter="url(#noise)" />
      </svg>

      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
