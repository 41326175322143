import React from "react";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "@uidotdev/usehooks";


function LanguageButton() {
    const {i18n } = useTranslation();
    const isLargeDevice = useMediaQuery("(min-width : 1024px)");


    const handleTrans = () => {
        i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
        localStorage.setItem("i18nextLng", i18n.language);

    };

    return (
        <button onClick={handleTrans} className={isLargeDevice ? "underline-animation" : "hover:text-primary-color"}>
            {i18n.language === "en" ? "DE" : "EN"}
        </button>
    );
}

export default LanguageButton;
